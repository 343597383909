import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { delete_outbound, methodPost } from '../../Api/Api';

export default function DeleteDialog({ 
     setMessage, setOpen, setStatus, setColor,
     openDeleteDialog,  setopenDeleteDialog , openDeleteDialogId , getOutBound
    }) {



    const handleClose = () =>{
        setopenDeleteDialog(false);
        getOutBound();
    }


    const deleteData = () =>{
        const sendData = new FormData()
        sendData.append('outboundId', openDeleteDialogId?.outboundId)
        axios({
          method: methodPost,
          url: delete_outbound,
          data:sendData, 
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            handleClose();
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        }).catch(err => {
          console.log(err)
        });
    }



  return (
      <Dialog onClose={handleClose} open={ openDeleteDialog}>
          <DialogTitle>
           Permanently Delete this Row ? 
          </DialogTitle>
          <DialogContent>
            <Stack spacing={1} p={3}>
                <Typography><strong>Category : </strong>{openDeleteDialogId?.categoryName}</Typography>
                <Typography><strong>Company Name : </strong>{openDeleteDialogId?.companyName}</Typography>
                <Typography><strong>Name : </strong>{openDeleteDialogId?.name}</Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Box p={2} display='flex' gap={2}>
            <Button onClick={handleClose} variant='contained' color='primary'>CLOSE</Button>
              <Button onClick={deleteData} variant='contained' color='error'>DELETE</Button>
            </Box>
          </DialogActions>
      </Dialog>
  )
}
