import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Box,Button,Card, TextField} from '@mui/material';
import { get_outbound, methodPost, update_outbound_follow_up_remarks } from '../../Api/Api';
import axios from 'axios';
import Filter from '../../components/Filter/Filter';
import { FilterBound, FilterOutBound } from '../../components/Filter/FilterData';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ExportCSV } from '../../components/ExportCSV';
import UpdateOutBound from './UpdateOutBound';
import DeleteDialog from './DeleteDialog';
import SnackBar from '../../components/SnackBar';

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [refreshpage, setrefreshpage] = useState(false);
    const [companyNameSearch , setCompanyNameSearch] = useState('');
    const [nameSearch , setNameSearch] = useState('');
    const [industrySearch , setIndustrySearch] = useState('');
    const [categorySearch , setCategorySearch] = useState('')
    const [callSearch , setCallSearch] = useState('')
    const [FollowSearch , setFollowSearch] = useState('')
    const [Additional , setAdditional] = useState('')
    const [editRow , setEditRow] = useState('')
    const [ShowAdditonal, setShowAdditonal] = useState(false)
    const [openDialogData , setOpenDialogData ] = useState(false);
    const [openDialog , setOpenDialog ] = useState(false);
    const [openDeleteDialog , setopenDeleteDialog ] = useState(false);
    const [openDeleteDialogId , setopenDeleteDialogId ] = useState('');
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


      const getOutBound = () =>{
        const sendData = new FormData()
        sendData.append('outboundId', "")
        axios({
          method: methodPost,
          url: get_outbound,
          cache: 'no-store',
          data:sendData, 
        }).then(res => {
          if (!res.data.error) {
            setData(res.data.data);
            setrefreshpage(false);
          } else {
            setData([]);
           
          }
        }).catch(err => {
          console.log(err)
        });
      }

      useEffect(() => {
        getOutBound();
    }, [refreshpage] );

    const keys = Object.keys(data[0] || {}).filter((key) => key !== "outboundId");

    const OpenAdditional = (i) =>{
      setOpenDialog(true)
      setOpenDialogData(i)
    }

    // const cancelClick = () =>{
    //     setEditRow('')
    //     setShowAdditonal(false);
    //     setAdditional('');
    // }

    // const updateAdditonal = (i) =>{
    //     const serverData = new FormData();
    //     serverData.append('outboundId', i.outboundId)
    //     serverData.append('followUpRemarks', Additional)
    //       axios({
    //         method: methodPost,
    //         url: update_outbound_follow_up_remarks,
    //         data: serverData,
    //       })
    //         .then((res) => {
    //           console.log(res);
    //           if (!res.data.error) {
    //             setMessage(res.data.message);
    //             setOpen(true);
    //             setStatus(true);
    //             setColor(true);
    //             setEditRow('')
    //             setShowAdditonal(false);
    //             setAdditional('');
    //             getOutBound();
    //           } else {
    //             setMessage(res.data.message);
    //             setOpen(true);
    //             setStatus(false);
    //             setColor(false);
    //           }
    //         })
    //         .catch((err) => {
    //           alert("Oops something went wrong " + err);
    //         });
    //        }

    const OpenDeleteDialogBox = (i)=>{
        setopenDeleteDialog(true)
        setopenDeleteDialogId(i)
    }

  return (
    <Box p={3}>
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
      <DeleteDialog getOutBound={getOutBound}  openDeleteDialogId={openDeleteDialogId} openDeleteDialog={openDeleteDialog} setopenDeleteDialog={setopenDeleteDialog} setOpen={setOpen} setStatus={setStatus} setColor={setColor} setMessage={setMessage} />
      <UpdateOutBound i={openDialogData} fetchTable={getOutBound} setOpen={setOpen} setStatus={setStatus} setColor={setColor} setMessage={setMessage} openDialog={openDialog} setOpenDialog={setOpenDialog} />
    <Box display='flex' justifyContent='space-between' my={1}>
      <h2 className='cg`'>View <span>Outbound</span></h2>
      <ExportCSV  fileName={'OutBound'} csvData={data} />
    </Box>
      <Card sx={{boxShadow:3}}>
      <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
              <TableRow>
              {keys.map((key) => (
             <TableCell sx={{ fontWeight: 600 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
                {key === "categoryName" && (
                <Box>
                <Filter search={categorySearch} setSearch={setCategorySearch} setPage={setPage} />
                </Box>
                )
                }
                {key === "name" && (
                <Box>
                <Filter search={nameSearch} setSearch={setNameSearch} setPage={setPage} />
                </Box>
                )
                }
                {key === "companyName" && (
                <Box>
                <Filter search={companyNameSearch} setSearch={setCompanyNameSearch} setPage={setPage} />
                </Box>
                )
                }
                {key === "callType" && (
                <Box>
                <Filter search={callSearch} setSearch={setCallSearch} setPage={setPage} />
                </Box>
                )
                }
                {key === "followUpTime" && (
                <Box>
                <Filter search={FollowSearch} setSearch={setFollowSearch} setPage={setPage} />
                </Box>
                )
                }
               {key === "industry" && (
                <Box>
                <Filter search={industrySearch} setSearch={setIndustrySearch} setPage={setPage} />
                </Box>
                )
                }
                </Box>
                </TableCell>
                ))}
               <TableCell sx={{ fontWeight: 600 , textAlign:'center' , textTransform:'uppercase'  }}>Update</TableCell>
               <TableCell sx={{ fontWeight: 600 , textAlign:'center' , textTransform:'uppercase'  }}>Delete</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
              {FilterOutBound(data,  categorySearch, nameSearch, companyNameSearch , FollowSearch , callSearch , industrySearch)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
              <TableRow key={index} sx={{bgcolor : index % 2 === 0 ? '#c3d4c8' : 'white'}}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    <Box width={(key === 'remarks' || key === 'followUpComments' || key === 'categoryName') ? '40ch' :'20ch'}>
                    {row[key] ? row[key] : "-" }
                    </Box>
                  </TableCell>
                ))}
              <TableCell>
              <Button variant='contained' color='success' onClick={()=>OpenAdditional(row)}>Update</Button>
              </TableCell>
              <TableCell>
              <Button variant='contained' color='error' onClick={()=>OpenDeleteDialogBox(row)}>Delete</Button>
              </TableCell>
              </TableRow>
            ))}
              </TableBody>
            </Table>
          </TableContainer><TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={FilterOutBound(data,  categorySearch, nameSearch, companyNameSearch , FollowSearch , callSearch , industrySearch).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage} />
      </Card>
      </Box>
      </Box>
      
    );
}