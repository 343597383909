import React,{useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SnackBar from '../../components/SnackBar';
import { useForm } from "react-hook-form";
import { add_inbound, add_outbound, get_crm_call_type, get_crm_category_name, get_crm_company_name, get_crm_industry_name, get_crm_person_type, get_excel_outbound, get_outbound, get_provinces, methodPost, update_outbound } from '../../Api/Api';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from 'react-router-dom';
import useFetch from '../../components/useFetch';
import { Autocomplete, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { parse } from 'date-fns';

export default function UpdateOutBound({fetchTable , i , setOpen ,setStatus ,setColor ,setMessage , openDialog, setOpenDialog}) {
  const theme = useTheme();
 

  const handleClose = () => {
    setOpenDialog(false);
    fetchTable();
    setAdditional('');
  };

  const [data , setData] = useState([]);
  const[show,showData]=useState(false);
  const[value, setValue]=useState(null)
  const [ExistingpersonType , setExistingPersonType] = useState(null);
  const [ExistingcallType , setExistingCallType] = useState(null);
  const [personType , setPersonType] = useState(null);
  const [callType , setCallType] = useState(null);
  const [Industry , setIndustry] = useState(null);
  const [ExistingIndustry , setExistingIndustry] = useState(null);
  const [buttonColor, setButtonColor] = useState('primary');
  const [Email , setEmail] = useState('');
  const [Name, setName] = useState('')
  const [companyName , setCompanyName] = useState(null);
  const [mobileNumber , setMobileNumber] = useState('')
  const [remarks, setRemarks] = useState('')
  const [comments, setComments] = useState('');
  const[provinceList , setProvinceList] = useState([]);
  const [errorMsg1, setErrorMsg1] = useState('');
  const [Error1 , setError1] = useState(false);
  const [websiteURL, setwebsiteURL] = useState('');
  const [personTypeList , setPersonTypeList] = useState([]);
  const [callTypeList , setCallTypeList] =  useState([]);
  const [IndustryList , setIndustryList] = useState([]);
  const [categoryList, setCategoryList] =  useState([]);
  const [followUpRemarks , setfollowUpRemarks] = useState('')
  const [Province, setProvince] = useState(null);
  const [ExistingProvince, setExistingProvince] = useState(null);
  const [category, setCategory] = useState(''); 
  const [Existingcategory, setExistingCategory] = useState(null);
  const [Existingcompany, setExistingCompany] = useState(null);

  const [manuallyEnteredProvince, setManuallyEnteredProvince] = useState('');
  const [manuallyEnteredCallType, setManuallyEnteredCallType] = useState('');
  const [manuallyEnteredpersonType, setManuallyEnteredpersonType] = useState('');
  const [manuallyEnteredcategory, setManuallyEnteredcategory] = useState('');
  const [manuallyEnteredIndustry, setManuallyEnteredIndustry] = useState('');
  const [manuallyEnteredcompanyName, setManuallyEnteredcompanyName] = useState('');

  const [SentmanuallyEnteredProvince, setSentManuallyEnteredProvince] = useState(false);
  const [SentmanuallyEnteredCallType, setSentManuallyEnteredCallType] =useState(false);
  const [SentmanuallyEnteredpersonType, setSentManuallyEnteredpersonType] = useState(false);
  const [SentmanuallyEnteredcategory, setSentManuallyEnteredcategory] = useState(false);
  const [SentmanuallyEnteredIndustry, setSentManuallyEnteredIndustry] = useState(false);
  const [SentmanuallyEnteredcompanyName, setSentManuallyEnteredcompanyName] = useState(false);

  const [position, setPosition] = useState(''); 
  const [fax, setFax] = useState(''); 
  const [address, setAddress] = useState(''); 
  const [city, setCity] = useState(''); 
  const [interests, setInterests] = useState('');
  const[companyNameList , setCompanyNameList] = useState([]);
  const [errorMsg2, setErrorMsg2] = useState('');
  const [Error2 , setError2] = useState(false);
  const [Additional , setAdditional] = useState('')
  const [errorMsg3, setErrorMsg3] = useState('');
  const [Error3 , setError3] = useState(false);

  const [errorMsg4, setErrorMsg4] = useState('');
  const [Error4 , setError4] = useState(false);

  const [errorMsg5, setErrorMsg5] = useState('');
  const [Error5 , setError5] = useState(false);

  const [errorMsg6, setErrorMsg6] = useState('');
  const [Error6, setError6] = useState(false);

  const [errorMsg7, setErrorMsg7] = useState('');
  const [Error7, setError7] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const handleChange = (e) => {
    setValue(e); // Update the form value
  };

  function convertDateFormatToAMPM(dateString) {
    const dateObj = new Date(dateString);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
  
    const formattedDate = `${year}/${month}/${day} ${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedDate;
  }
  
  const formattedDate = convertDateFormatToAMPM(value);
    

  const validateInput = () =>{
    if(Email !== ''){
    if (/\S+@\S+\.\S+/.test(Email)) {
      setErrorMsg7("");
      setError7(false);
      } else {
      setError7(true);
      setErrorMsg7("Invalid Email Address");
      }
    }
    else{
      setError7(true);
      setErrorMsg7("Email Address Cannot Be Empty");
    }
  }

  useEffect(() =>{
    axios({
          method: "GET",
          url: get_provinces,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
                setProvinceList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])


  useEffect(() =>{
    axios({
          method: "GET",
          url: get_crm_company_name,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
              setCompanyNameList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])



  useEffect(() =>{
    axios({
          method: "GET",
          url: get_crm_category_name,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
                setCategoryList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])


  useEffect(() =>{
    axios({
          method: "GET",
          url: get_crm_industry_name,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
                setIndustryList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])


  useEffect(() =>{
    axios({
          method: "GET",
          url: get_crm_person_type,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
                setPersonTypeList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])

  useEffect(() =>{
    axios({
          method: "GET",
          url: get_crm_category_name,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
                setCategoryList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])

  useEffect(() =>{
    axios({
          method: "GET",
          url: get_crm_call_type,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
                setOpen(true);
            } else {
                setCallTypeList(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
  },[])


  const options = provinceList.map((i)=>{
    const obj = {
      'label':i.ProvinceName,
      'code': i.provinceCode,
      'id':i.provinceId
    }
    return obj ;
   });

   const Companyoptions = companyNameList.map((i)=>{
    const obj = {
      'label':i.companyName,
      'id':i.companyId
    }
    return obj ;
   });


   const Industryoptions = IndustryList.map((i)=>{
    const obj = {
      'label':i.industryName,
      'id':i.industryId
    }
    return obj ;
   });

   const Categoryoptions = categoryList.map((i)=>{
    const obj = {
      'label':i.categoryName,
      'id':i.categoryId
    }
    return obj ;
   });

   const personTypeoptions = personTypeList.map((i)=>{
    const obj = {
      'label':i.personType,
      'id':i.personId
    }
    return obj ;
   });

   const callTypeoptions = callTypeList.map((i)=>{
    const obj = {
      'label':i.callType,
      'id':i.callId
    }
    return obj ;
   });

  const tomorrow = dayjs().add(1, 'day');

  const getParticularOutBound = () =>{
    if (i?.outboundId !== null && i?.outboundId !== "" && i?.outboundId !== undefined){
    const sendData = new FormData()
    sendData.append('outboundId', i?.outboundId)
    axios({
          method: methodPost,
          url: get_outbound,
          data:sendData
        }).then(res => {
          if (!res.data.error) {
            setExistingIndustry(res.data.data.industry);
            setIndustry(res.data.data.industry);
            setEmail(res.data.data.email);
            setName(res.data.data.name);
            setCompanyName(res.data.data.companyName);
            setExistingCompany(res.data.data.companyName);
            setMobileNumber(res.data.data.mobile);
            setRemarks(res.data.data.remarks);
            setComments(res.data.data.comments);
            setExistingProvince(res.data.data.province);
            setProvince(res.data.data.province);
            setExistingCategory(res.data.data.categoryName); 
            setCategory(res.data.data.categoryName); 
            setPosition(res.data.data.position);
            setFax(res.data.data.fax);
            setAddress(res.data.data.contactAddress); 
            setCity(res.data.data.city); 
            setInterests(res.data.data.interest);
            setwebsiteURL(res.data.data.websiteURL);
            setPersonType(res.data.data.personType)
            setCallType(res.data.data.callType)
            setExistingPersonType(res.data.data.personType)
            setExistingCallType(res.data.data.callType)
            setComments(res.data.data.followUpComments)
            setValue(res.data.data.followUpTime !== '' ? res.data.data.followUpTime : null)
            showData(res.data.data.followUpTime !== '' ? true : false);
            setAdditional(res.data.data.followUpRemarks)
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        }).catch(err => {
          console.log(err)
        });
      }
  }

  useEffect(()=>{
  getParticularOutBound();
  },[i])


  const onSubmit = () => {
    if (show && (comments === '' || value === '')) {
      setMessage('Please Fill Follow up');
      setOpen(true);
      setStatus(false);
      setColor(false);
      return;
    }
    const serverData = new FormData();
    serverData.append('outboundId', i?.outboundId)
    serverData.append('followUpTime', show ? formattedDate : "")
    serverData.append('personType', SentmanuallyEnteredpersonType ? manuallyEnteredpersonType : personType?.label);
    serverData.append('callType', SentmanuallyEnteredcategory ? manuallyEnteredCallType :  callType?.label);
    serverData.append('industry', SentmanuallyEnteredIndustry ? manuallyEnteredIndustry : Industry?.label)
    serverData.append('email', Email)
    serverData.append('name', Name)
    serverData.append('companyName', SentmanuallyEnteredcompanyName ? manuallyEnteredcompanyName : companyName?.label)
    serverData.append('mobile', mobileNumber)
    serverData.append('remarks', remarks)
    serverData.append('followUpComments', show ? comments : '')
    serverData.append('websiteURL', websiteURL)
    serverData.append('contactAddress', address)
    serverData.append('city', city)
    serverData.append('province', SentmanuallyEnteredProvince ? manuallyEnteredProvince : Province?.label)
    serverData.append('interest', interests)
    serverData.append('position', position)
    serverData.append('categoryName', SentmanuallyEnteredcategory ? manuallyEnteredcategory : category?.label)
    serverData.append('followUpRemarks', Additional)

    if (!navigator.onLine) {
      setMessage("Your internet is in Offline");
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      axios({
        method: methodPost,
        url: update_outbound,
        data: serverData,
      })
        .then((res) => {
          console.log(res);
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            handleClose();
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
  }
}



  return (
      <Dialog
        maxWidth
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update OutBound"}
        </DialogTitle>
        <DialogContent>

   <Container> 
        <Box>
          <Box mt={1} boxShadow={3} bgcolor='#EAF4FC' borderRadius={2}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box p={3}>
            
     
            <Grid container spacing={3}>

            <Grid item xs={12} sm={6} md={4} lg={4}>
             
            <Autocomplete
             disablePortal
             id="combo-box-demo"
             options={Categoryoptions}
             fullWidth
             freeSolo
             onInputChange={(event, newInputValue)=>{
              setManuallyEnteredcategory(newInputValue)
              setSentManuallyEnteredcategory(true)
             }}
             value={category}
             onChange={(event, value)=>{
              setSentManuallyEnteredcategory(false)
              setCategory(value)
             }}
             renderInput={(params) => <TextField {...params}   required  size='small' label="Category" />}
             />
             </Grid>

             <Grid item xs={12} sm={6} md={4} lg={4}>
             <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Companyoptions}
            size='small'
            required
            freeSolo
            onInputChange={(event, newInputValue)=>{
              setManuallyEnteredcompanyName(newInputValue)
              setSentManuallyEnteredcompanyName(true)
             }}
            onChange={(event, value) =>{
              setCompanyName(value)
              setSentManuallyEnteredcompanyName(false)
            }}
            value={companyName}
            renderInput={(params) => <TextField {...params}  required label='Company Name' />}
            />
              </Grid>


              <Grid item xs={12} sm={6} md={4} lg={4}>
                       
                <TextField
                  autoComplete="given-name"
                  name="Name"
                  required
                  fullWidth
                  label="Contact Person Name"
                  autoFocus
                  size='small'
                  value={Name}
                  helperText={errorMsg2}
                  error={Error2}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    var regName = /^[A-Za-z\s]+$/; 
                      if (newValue.match(regName) || newValue === "") {
                    setErrorMsg2("");
                    setError2(false);
                    setName(newValue); // only set when successful
                    } else {
                    setError2(true);
                    setErrorMsg2("Numeric and Special characters are not allowed");
                    }
                    }}
                />
              </Grid>

              
            <Grid item xs={12} sm={6} md={4} lg={4}>
           
              <TextField
                  autoComplete="given-name"
                  name="CompanyName"  
                  required
                  fullWidth
                  label="Position"
                  autoFocus
                  size='small'
                  value={position}
                  helperText={errorMsg3}
                  error={Error3}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    var regName = /^[A-Za-z\s]+$/; 
                      if (newValue.match(regName) || newValue === "") {
                    setErrorMsg3("");
                    setError3(false);
                    setPosition(newValue); // only set when successful
                    } else {
                    setError3(true);
                    setErrorMsg3("Numeric and Special characters are not allowed");
                    }
                    }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                 
              <Autocomplete
                 disablePortal
                 id="combo-box-demo"
                 options={Industryoptions}
                 fullWidth
                 freeSolo
                 required
                 value={Industry}
                 onInputChange={(event, newInputValue)=>{
                  setManuallyEnteredIndustry(newInputValue)
                  setSentManuallyEnteredIndustry(true)
                 }}
                onChange={(event, value) =>{
                  setIndustry(value)
                  setSentManuallyEnteredIndustry(false)
                }}
                 renderInput={(params) => <TextField {...params}   required  size='small' label="Industry" />}
                 />
                 </Grid>
            
            <Grid item xs={12} sm={6} md={4} lg={4}>
             
              
              <TextField
                  name="Email"
                  required
                  fullWidth
                  label="Email Address"
                  size='small'
                  value={Email}
                  helperText={errorMsg7}
                  error={Error7}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setEmail(newValue);
                    }}
                    onBlur={validateInput}
                />
              </Grid>

        
            <Grid item xs={12} sm={6} md={4} lg={4}>
             
             
            
              <TextField
                name="Contact No"
                  required
                  fullWidth
                  label="Contact No"
                  size='small'
                  value={mobileNumber}
                  inputProps={{
                    maxLength: 13,
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue.match(/^[0-9]+$/)){
                        setMobileNumber(parseInt(newValue));
                    }
                    else{
                      setMobileNumber('');
                    } 
                  }}
                />
              </Grid>


            <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
            name="Website URL"
            fullWidth
            label="Website URL"
            size="small"
            value={websiteURL}
            onChange={(e) =>setwebsiteURL(e.target.value)}
            />
             </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
            
              <TextField
                  autoComplete="given-name"
                  name="address"  
                  required
                  fullWidth
                  label="Street"
                  autoFocus
                  size='small'
                  value={address}
                  helperText={errorMsg4}
                  error={Error4}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setAddress(newValue); 
                  }}
                />
              </Grid>


            <Grid item xs={12} sm={6} md={4} lg={4}>
             
              <TextField
                  autoComplete="given-name"
                  name="city"  
                  required
                  fullWidth
                  label="City"
                  autoFocus
                  size='small'
                  value={city}
                  helperText={errorMsg5}
                  error={Error5}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    var regName = /^[A-Za-z\s]+$/; 
                      if (newValue.match(regName) || newValue === "") {
                    setErrorMsg5("");
                    setError5(false);
                    setCity(newValue); // only set when successful
                    } else {
                    setError5(true);
                    setErrorMsg5("Numeric and Special characters are not allowed");
                    }
                    }}
                />
              </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            size='small'
            freeSolo
            required
            value={Province}
            onInputChange={(event, newInputValue)=>{
              setManuallyEnteredProvince(newInputValue)
              setSentManuallyEnteredProvince(true)
             }}
            onChange={(event, value) =>{
              setProvince(value)
              setSentManuallyEnteredProvince(false)
            }}
            renderInput={(params) => <TextField {...params}  required label='Province' />}
            />
                  </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
             
              <TextField
                  autoComplete="given-name"
                  name="interests"  
                  required
                  fullWidth
                  label="Interests"
                  autoFocus
                  size='small'
                  value={interests}
                  helperText={errorMsg6}
                  error={Error6}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    var regName = /^[A-Za-z\s]+$/; 
                      if (newValue.match(regName) || newValue === "") {
                    setErrorMsg6("");
                    setError6(false);
                    setInterests(newValue); // only set when successful
                    } else {
                    setError6(true);
                    setErrorMsg6("Numeric and Special characters are not allowed");
                    }
                    }}
                />
              </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
  
            <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={personTypeoptions}
                  fullWidth
                  freeSolo
                  required
                  value={personType}
                  onInputChange={(event, newInputValue)=>{
                    setManuallyEnteredpersonType(newInputValue)
                    setSentManuallyEnteredpersonType(true)
                   }}
                  onChange={(event, value) =>{
                    setPersonType(value)
                    setSentManuallyEnteredpersonType(false)
                  }}
                  renderInput={(params) => <TextField {...params}  required   size='small' label="Contact Person Type" />}
                  />
                  </Grid>



            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={callTypeoptions}
                  fullWidth
                  freeSolo
                  required
                  value={callType}
                  onInputChange={(event, newInputValue)=>{
                    setManuallyEnteredCallType(newInputValue)
                    setSentManuallyEnteredCallType(true)
                   }}
                  onChange={(event, value) =>{
                    setCallType(value)
                    setSentManuallyEnteredCallType(false)
                  }}
                  renderInput={(params) => <TextField {...params}   required  size='small' label="Call Type" />}
                  />
                  </Grid>
                         
       
                  <Grid item xs={12}>
             
                  <TextField 
              name='Remarks'
            
              fullWidth
              label="Remarks"
              multiline
              required
               rows={3} 
              placeholder='Type Your Remarks'
              size='small'
              inputProps={{
                maxLength: 300,
              }}
              value={remarks}
              onChange={(e)=>setRemarks(e.target.value)}
            />
              </Grid>

      
              <Grid item xs={12}>
              <Box>
              <h4>Further Follow up require</h4>
              <Box display='flex' flexDirection='row' gap={2}>
              <Button
              variant="contained"
              color={show ? 'success' :'primary'}
              sx={{ mt:1, mb: 1 }} type="button" onClick={()=>{
                    showData(true);
                    setButtonColor('primary');
                }}>Yes</Button> 
             
                <Button 
              variant="contained"
             color={buttonColor}
              sx={{ mt:1, mb: 1 }} type="button" onClick={()=>{
                showData(false);
                setButtonColor('success')
              }}>No</Button>
            </Box>
              
                           
            {show?  
            <Grid container spacing={2} flexDirection='column'>

            <Grid item xs={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer size='small' components={['DateTimePicker']}>
            <DateTimePicker size='small'  value={dayjs(value)} minDate={tomorrow} disablePast name="followUpTime" onChange={handleChange}  />
            </DemoContainer>
            </LocalizationProvider>
            </Grid>

            <Grid item xs={12} lg={6}>
            <TextField 
            name='comments'
            fullWidth
            label="comments"
            multiline
            placeholder='Comments'
            inputProps={{
                maxLength: 300,
            }}
            InputLabelProps={{
                shrink:true
            }}
            value={comments}
            onChange={(e)=>setComments(e.target.value)}
            />
            </Grid>
            </Grid>
            :null}
            
            <Grid item xs={12} py={3}>
            <TextField 
              name='additional'
              fullWidth
              multiline
              inputProps={{
                maxLength: 500,
               }}
               rows={2}
              label="Additional Information"
              size='small'
              value={Additional}
              onChange={(e)=>setAdditional(e.target.value)}
              />
            </Grid>
            
      
              </Box>   
             </Grid>
              </Grid>
           
                
            
              <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={6}>
            <Button 
            variant="contained"
            color='error'
            fullWidth
            onClick={handleClose}
            sx={{mt:2}}
          >
            Cancel
          </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
            type="submit"
            fullWidth
            variant="contained"
            color='warning'
            sx={{mt:2}}
          >
            Submit
          </Button>
          </Grid>
            </Grid>

            </Box>
            </Form>
          </Box>
        </Box>
      </Container>
        </DialogContent>
      </Dialog>
  );
}